import React, { useState, useEffect, useRef } from 'react'

import Lockup from '../media/lockup.webm'
import LockupSafari from '../media/lockup-safari.mov'
import LockupStill from '../media/lockup-still.png'

export default function LockupComponent(props) {
    const [loading, setLoading] = useState(0)
    const videoRef = useRef()
    useEffect(() => {
        if (loading === 1) {
            props.incrementLoading()
        }
    }, [loading])

    var testEl = document.createElement("video"),
        webm;
    if (testEl.canPlayType) {
        // Check for Webm support
        var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') === -1 &&
            navigator.userAgent.indexOf('FxiOS') === -1;
        webm = "" !== testEl.canPlayType('video/webm; codecs="vp8, vp9, vorbis"');
        if (isSafari) {
            webm = false;
        }
        navigator.saysWho = (() => {
            const { userAgent } = navigator
            let match = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
            let temp

            if (/trident/i.test(match[1])) {
                temp = /\brv[ :]+(\d+)/g.exec(userAgent) || []

                return `IE ${temp[1] || ''}`
            }

            if (match[1] === 'Chrome') {
                temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/)

                if (temp !== null) {
                    return temp.slice(1).join(' ').replace('OPR', 'Opera')
                }

                temp = userAgent.match(/\b(Edg)\/(\d+)/)

                if (temp !== null) {
                    return temp.slice(1).join(' ').replace('Edg', 'Edge (Chromium)')
                }
            }

            match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, '-?']
            temp = userAgent.match(/version\/(\d+)/i)

            if (temp !== null) {
                match.splice(1, 1, temp[1])
            }

            return match.join(' ')
        })()
    }
    return (
        <>
                {webm ?
                    <video
                        muted
                        src={Lockup}
                        autoPlay
                        preload={'auto'}
                        type={'video/webm'}
                        className='lockup fade-image'
                        onPlay={() => setLoading(loading => loading + 1)}
                        poster="noposter"
                        playsInline
                        disableRemotePlayback
                        ref={videoRef}
                    />
                    : (navigator.saysWho.substring(7, 9) >= 11 ?
                        <video
                            muted
                            autoPlay
                            src={LockupSafari}
                            preload={'auto'}
                            type={'video/mov'}
                            className='lockup'
                            onPlay={() => setLoading(loading => loading + 1)}
                            poster="noposter"
                            playsInline
                            disableRemotePlayback
                            ref={videoRef}
                        />
                        : <img src={LockupStill} alt="COSYFISH LOGO" className="fade-image img-fluid" onLoad={() => setLoading(loading => loading + 1)} />
                    )}
        </>
    )
}