import React, { useState, useEffect } from 'react'

import SnowunsCharacters from '../media/snowuns-characters.png'
import SnowunsMerch from '../media/snowuns-merch.png'
import SnowunsText1 from '../media/snowuns-text1.png'
import SnowunsReviews from '../media/snowuns-reviews.png'
import SnowunsBook from '../media/snowuns-book.png'
import SnowunsMobileText from '../media/snowuns-mobile-text.png'
import SnowunsMobileCharacters from '../media/snowuns-mobile-characters.png'
import SnowunsMobileMerch from '../media/snowuns-mobile-merch.png'
import SnowunsCharactersP from '../media/snowuns-characters.webp'
import SnowunsMerchP from '../media/snowuns-merch.webp'
import SnowunsText1P from '../media/snowuns-text1.webp'
import SnowunsReviewsP from '../media/snowuns-reviews.webp'
import SnowunsBookP from '../media/snowuns-book.webp'
import SnowunsMobileTextP from '../media/snowuns-mobile-text.webp'
import SnowunsMobileCharactersP from '../media/snowuns-mobile-characters.webp'
import SnowunsMobileMerchP from '../media/snowuns-mobile-merch.webp'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import BeatLoader from 'react-spinners/BeatLoader'
import useWindowDimensions from './useWindowDimensions';
import { Col, Container, Row } from 'react-bootstrap'
import dynamicImage from '../utils/dynamicImage'
export default function Snowuns(props) {
    const [loading, setLoading] = useState(0)
    const { height, width, heightMargin, widthMargin, mobile } = useWindowDimensions();
    useEffect(() => {
        if (loading === 0) {
            props.startLoading()
            props.incrementLoading()
        }
        if ((mobile === true && loading > 3) || (mobile === false && loading > 4)) {
            props.incrementLoading()
        }
    }, [loading])

    const useProgressiveImage = src => {
        const [sourceLoaded, setSourceLoaded] = useState(null)
        useEffect(() => {
            const img = new Image()
            img.src = src
            img.onload = () => {
                setLoading(loading => loading + 1)
                setSourceLoaded(src)
            }
        }, [src])
        return sourceLoaded
    }
    const SnowunsCharacterImage = useProgressiveImage(mobile ? null : dynamicImage(props.supported, SnowunsCharacters, SnowunsCharactersP))
    const SnowunsMerchImage = useProgressiveImage(mobile ? null : dynamicImage(props.supported, SnowunsMerch, SnowunsMerchP))
    const SnowunsText1Image = useProgressiveImage(mobile ? null : dynamicImage(props.supported, SnowunsText1, SnowunsText1P))
    const SnowunsReviewsImages = useProgressiveImage(dynamicImage(props.supported, SnowunsReviews, SnowunsReviewsP))
    const SnowunsBookImage = useProgressiveImage(mobile ? null : dynamicImage(props.supported, SnowunsBook, SnowunsBookP))
    const SnowunsMobileTextImage = useProgressiveImage(mobile ? dynamicImage(props.supported, SnowunsMobileText, SnowunsMobileTextP) : null)
    const SnowunsMobileCharactersImage = useProgressiveImage(mobile ? dynamicImage(props.supported, SnowunsMobileCharacters, SnowunsMobileCharactersP) : null)
    const SnowunsMobileMerchImage = useProgressiveImage(mobile ? dynamicImage(props.supported, SnowunsMobileMerch, SnowunsMobileMerchP) : null)
    const SnowunsLogoImage = props.logo
    function TextCalc() {
        let widthSize = (width / 60)
        let heightSize = (heightMargin / 50)
        if (heightSize > widthSize) {
            return heightSize + 1
        } else {
            return widthSize
        }
    }
    if ((mobile === true && loading > 3) || (mobile === false && loading > 4)) {
        return (
            <>
                <div className={props.className}>
                    <div className="brand-background fade-in snowuns-background">
                    </div>
                    <div className="brand-mobile" style={{ fontSize: (width > 575 ? 27 : width / 20) }}>
                        <div>
                            <Container className="z-index-9999">
                                <Row>
                                    <Col>
                                        <img src={SnowunsLogoImage} className="snowuns-mobile-logo" alt="Snowuns logo" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <img src={SnowunsMobileTextImage} className="snowuns-mobile-text" alt="Snowuns Text" />
                                        <a rel="noopener noreferrer" href="https://www.amazon.co.uk/Snowuns-Save-Sea-Cosyfish-Limited/dp/B08Y4H448F/ref=sr_1_1?keywords=snowuns+save+the+sea&qid=1632658050&qsid=260-4486335-4857562&sr=8-1&sres=B08R3YPF6K%2CB0795VFQ31%2CB09B15BYVZ%2CB07FK2KFWL%2CB00A4B4B3M%2CB08FJCH2B1%2CB07KS1NR4X%2CB003C5K9LU%2CB0758QDMC5%2CB08LZLGQ61%2CB0827NJ1SY%2CB00025K166%2CB01EMIOF3S%2CB08W8K32BZ%2C0718187733%2CB0799NNK89" target="_blank" className="snowuns-book-link-mobile snowuns-link">Link To Book</a>
                                    </Col>
                                </Row>
                                <Row>
                                    <a rel="noopener noreferrer" href="https://www.amazon.co.uk/Snowuns-Save-Sea-Cosyfish-Limited/dp/B08Y4H448F/ref=sr_1_1?keywords=snowuns+save+the+sea&qid=1632658050&qsid=260-4486335-4857562&sr=8-1&sres=B08R3YPF6K%2CB0795VFQ31%2CB09B15BYVZ%2CB07FK2KFWL%2CB00A4B4B3M%2CB08FJCH2B1%2CB07KS1NR4X%2CB003C5K9LU%2CB0758QDMC5%2CB08LZLGQ61%2CB0827NJ1SY%2CB00025K166%2CB01EMIOF3S%2CB08W8K32BZ%2C0718187733%2CB0799NNK89#customerReviews" target="_blank" className="z-index-9999">
                                        <Col>
                                            <img src={SnowunsReviewsImages} className="snowuns-mobile-reviews" alt="Snowuns review" />
                                        </Col>
                                    </a>

                                </Row>
                            </Container>
                            <img src={SnowunsMobileCharactersImage} alt="Snowuns Characters" className="snowuns-mobile-characters z-index-0" />
                        </div>
                        <div className="snowuns-mobile-footer">
                            {/*<a href="https://www.amazon.co.uk/dp/B08Y4H448F" className="snowuns-store-link-mobile snowuns-link" style={{}}>Link to Store</a>*/}
                            <img src={SnowunsMobileMerchImage} alt="Snowuns Merch" className="snowuns-mobile-merch" />
                        </div>
                    </div>
                    <div style={{ height: height, width: width, marginTop: heightMargin, marginBottom: heightMargin, marginLeft: widthMargin, marginRight: widthMargin, position: 'absolute', fontSize: TextCalc() }} className="desktop-page">
                        <img className="snowuns-logo" src={SnowunsLogoImage} alt="Snowuns Logo" />
                        <img className="snowuns-characters" src={SnowunsCharacterImage} alt="Snowuns Characters" />
                        <img className="snowuns-book" src={SnowunsBookImage} alt="Snowuns Book" />
                        <img className="snowuns-text1" src={SnowunsText1Image} alt="Snowuns Text1" />
                        <a rel="noopener noreferrer" href="https://www.amazon.co.uk/Snowuns-Save-Sea-Cosyfish-Limited/dp/B08Y4H448F/ref=sr_1_1?keywords=snowuns+save+the+sea&qid=1632658050&qsid=260-4486335-4857562&sr=8-1&sres=B08R3YPF6K%2CB0795VFQ31%2CB09B15BYVZ%2CB07FK2KFWL%2CB00A4B4B3M%2CB08FJCH2B1%2CB07KS1NR4X%2CB003C5K9LU%2CB0758QDMC5%2CB08LZLGQ61%2CB0827NJ1SY%2CB00025K166%2CB01EMIOF3S%2CB08W8K32BZ%2C0718187733%2CB0799NNK89" target="_blank" className="snowuns-book-link snowuns-link">Link To Book</a>
                        <a rel="noopener noreferrer" href="https://www.amazon.co.uk/Snowuns-Save-Sea-Cosyfish-Limited/dp/B08Y4H448F/ref=sr_1_1?keywords=snowuns+save+the+sea&qid=1632658050&qsid=260-4486335-4857562&sr=8-1&sres=B08R3YPF6K%2CB0795VFQ31%2CB09B15BYVZ%2CB07FK2KFWL%2CB00A4B4B3M%2CB08FJCH2B1%2CB07KS1NR4X%2CB003C5K9LU%2CB0758QDMC5%2CB08LZLGQ61%2CB0827NJ1SY%2CB00025K166%2CB01EMIOF3S%2CB08W8K32BZ%2C0718187733%2CB0799NNK89#customerReviews" target="_blank" className="z-index-9999">
                            <img src={SnowunsReviewsImages} alt="Amazon reviews" className="snowuns-reviews" />
                        </a>
                        {/*<a href="#" className="snowuns-store snowuns-link">Link to Store</a>*/}
                        <div className="brand-footer snowuns-footer">
                            <img className="snowuns-merch" src={SnowunsMerchImage} alt="Snowuns Merch" />
                        </div>
                    </div>
                    <div className="brand-flexi-footer desktop-page snowuns-footer" style={{ height: (heightMargin + (height * 0.275)) }}>
                    </div>
                    <FontAwesomeIcon icon={faTimes} size="3x" onClick={() => props.goToBrands()} className="close-button-small-screen" />
                    <FontAwesomeIcon icon={faTimes} size="5x" onClick={() => props.goToBrands()} className="close-button-large-screen" />
                </div>
            </>
        )
    } else {
        return (
            <div className="center">
                <BeatLoader color='#583c96' />
            </div>
        );
    }
}