import { Link } from 'react-router-dom'
export default function CosyfishButton(props) {
    if (props.link !== undefined) {
        return (
            <Link to={props.link}><button type="button" className={"cosyfish-button " + props.className}>{props.content}</button></Link>
        )
    } else {
        return (
            <button type="button" onClick={props.onClick} className={"cosyfish-button " + props.className}>{props.content}</button>
        )
    }
}