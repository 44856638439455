import React, { useState, useEffect } from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BrandTile from './BrandTile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Locked from '../media/zombie-logo2.png'
import LockedP from '../media/zombie-logo2.webp'
import dynamicImage from '../utils/dynamicImage';
export default function BrandGrid(props) {
    const [close, setClose] = useState(false)
    const [isLocked, setIsLocked] = useState(false)
    const [slideCloseButton, setSlideCloseButton] = useState(false)
    useEffect(() => {
        if (props.hideBrands === true) {
            setClose(true)
            setTimeout(function () {
                setSlideCloseButton(true)
            }, 400);
        } else {
            setSlideCloseButton(false)
            setClose(false)
        }
    }, [props.hideBrands])
    function goToPage(url) {
        props.goToPage(url)
        setSlideCloseButton(true)
    }
    function closeGrid() {
        setClose(true)
        props.closeGrid()
        setTimeout(function () {
            setSlideCloseButton(true)
        }, 400);
    }
    return (
        <Container fluid className={"p-0 " + props.className}>
            <Row className="half-height">
                <FontAwesomeIcon icon={faTimes} size="3x" onClick={() => closeGrid()} className={"close-button-small-screen " + (slideCloseButton === false ? "slide-in-tl" : "slide-out-tl")} />
                <Col className="p-0 auto-height clickable" sm={12} lg={6} onClick={() => closeGrid()}>
                    <BrandTile image={props.bg1} logo={props.logo1} close={close} exitAnimation="slide-out-tl" animation="slide-in-tl"></BrandTile>
                </Col>
                <FontAwesomeIcon icon={faTimes} size="5x" onClick={() => closeGrid()} className={"close-button-large-screen " + (slideCloseButton === false ? "slide-in-tr" : "slide-out-tr")} />
                <Col className="p-0 auto-height clickable" sm={12} lg={6} onClick={() => goToPage('/brands/snowuns')}>
                    <BrandTile image={props.bg2} logo={props.logo2} close={close} exitAnimation="slide-out-tr" animation="slide-in-tr" />
                </Col>
            </Row>
            <Row className="half-height">
                <Col className="p-0 auto-height clickable" sm={12} lg={6} onClick={() => setIsLocked(true)} /*onClick={() => goToPage('/brands/zombie-snowmen')}*/ >
                    <BrandTile image={props.bg3} logo={isLocked ? dynamicImage(props.supported, Locked, LockedP) : props.logo3} close={close} exitAnimation="slide-out-bl" animation="slide-in-bl">
                    </BrandTile>
                </Col>
                <Col className="p-0 auto-height clickable" sm={12} lg={6} onClick={() => goToPage('/brands/bean')}>
                    <BrandTile image={props.bg4} logo={props.logo4} close={close} exitAnimation="slide-out-br" animation="slide-in-br" />
                </Col>
            </Row>
        </Container>
    );
}