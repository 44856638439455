import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import BeanDesings from '../media/bean-designs.png'
import BeanProducts from '../media/bean-product.png'
import BeanType from '../media/bean-type.png'
import BeanCharacter from '../media/bean-character.png'
import BeanTypeMobile from '../media/bean-type-mobile.png'
import BeanProductMobile from '../media/bean-product-mobile.png'
import BeanDesingsP from '../media/bean-designs.webp'
import BeanProductsP from '../media/bean-product.webp'
import BeanTypeP from '../media/bean-type.webp'
import BeanCharacterP from '../media/bean-character.webp'
import BeanTypeMobileP from '../media/bean-type-mobile.webp'
import BeanProductMobileP from '../media/bean-product-mobile.webp'
import Instagram from '../media/insta.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import BeatLoader from 'react-spinners/BeatLoader'
import useWindowDimensions from './useWindowDimensions';
import dynamicImage from '../utils/dynamicImage'
export default function Bean(props) {
    const [loading, setLoading] = useState(0)
    const { height, width, heightMargin, widthMargin, mobile } = useWindowDimensions();
    useEffect(() => {
        if (loading === 0) {
            props.startLoading()
            props.incrementLoading()
        }
        if ((mobile === true && loading > 1) || (mobile === false && loading > 3)) {
            props.incrementLoading()
        }
    }, [loading])

    const useProgressiveImage = src => {
        const [sourceLoaded, setSourceLoaded] = useState(null)
        useEffect(() => {
            const img = new Image()
            img.src = src
            img.onload = () => {
                setLoading(loading => loading + 1)
                setSourceLoaded(src)
            }
        }, [src])
        return sourceLoaded
    }
    const BeanDesignsImage = useProgressiveImage(mobile ? null : dynamicImage(props.supported, BeanDesings, BeanDesingsP))
    const BeanProductsImage = useProgressiveImage(mobile ? null : dynamicImage(props.supported, BeanProducts, BeanProductsP))
    const BeanTypeImage = useProgressiveImage(mobile ? null : dynamicImage(props.supported, BeanType, BeanTypeP))
    const BeanCharacterImage = useProgressiveImage(mobile ? null : dynamicImage(props.supported, BeanCharacter, BeanCharacterP))
    const BeanProductsMobileImage = useProgressiveImage(mobile ? dynamicImage(props.supported, BeanProductMobile, BeanProductMobileP) : null)
    const BeanTypeMobileImage = useProgressiveImage(mobile ? dynamicImage(props.supported, BeanTypeMobile, BeanTypeMobileP) : null)
    const BeanLogoImage = props.logo

    if ((mobile === true && loading > 1) || (mobile === false && loading > 3)) {
        return (
            <>
                <div className={props.className}>
                    <div className="brand-background fade-in bean-background">
                    </div>
                    <div className="brand-mobile">
                        <Container>
                            <Row>
                                <Col>
                                    <img src={BeanLogoImage} className="bean-logo-mobile img-fluid" alt="Bean logo" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <a rel="noopener noreferrer" href="https://www.instagram.com/cosy_bean/" target="_blank" className="z-index-9999 bean-instagram-mobile">
                                        <img src={Instagram} alt="Instagram Logo" className="w-100" />
                                    </a>
                                    <img src={BeanTypeMobileImage} className="bean-type-mobile img-fluid" alt="Bean type" />
                                    <a rel="noopener noreferrer" href="https://www.etsy.com/uk/shop/CosyfishDesigns?ref=shop_sugg" target="_blank" className="bean-link-mobile">Link to Store</a>
                                </Col>
                            </Row>
                        </Container>
                        <div className="bean-mobile-footer">
                            <img src={BeanProductsMobileImage} className="bean-products-mobile" alt="Bean products" />
                        </div>
                    </div>
                    <div style={{ height: height, width: width, marginTop: heightMargin, marginBottom: heightMargin, marginLeft: widthMargin, marginRight: widthMargin, position: 'absolute' }} className="desktop-page">
                        <img src={BeanLogoImage} className="bean-logo" alt="Bean logo" />
                        <img src={BeanProductsImage} className="bean-products" alt="Bean products" />
                        <a rel="noopener noreferrer" href="https://www.instagram.com/cosy_bean/" target="_blank" className="z-index-9999 bean-instagram">
                            <img src={Instagram} alt="Instagram Logo" className="w-100" />
                        </a>
                        <img src={BeanTypeImage} className="bean-type z-index-0" alt="Bean type" />
                        <a rel="noopener noreferrer" href="https://www.etsy.com/uk/shop/CosyfishDesigns?ref=shop_sugg" target="_blank" className="bean-link" style={{ fontSize: (width / 70) }}>Link to Store</a>
                        <img src={BeanCharacterImage} className="bean-character" alt="Bean character" />
                        <div className="brand-footer bean-footer">
                            <img src={BeanDesignsImage} className="bean-designs" alt="Bean designs" />
                        </div>
                    </div>
                    <div className="brand-flexi-footer desktop-page bean-footer" style={{ height: (heightMargin + (height * 0.275)) }}>
                    </div>
                    <FontAwesomeIcon icon={faTimes} size="3x" onClick={() => props.goToBrands()} className="close-button-small-screen" />
                    <FontAwesomeIcon icon={faTimes} size="5x" onClick={() => props.goToBrands()} className="close-button-large-screen" />
                </div>
            </>
        );
    } else {
        return (
            <div className="center">
                <BeatLoader color='#583c96' />
            </div>
        );
    }

}