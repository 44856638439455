import React, { useState } from 'react';
import axios from 'axios';
import { Container, Alert, Row, Col, Form } from 'react-bootstrap';
import {
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

export default function Contact(props) {
    const [error, setError] = useState(null);
    const [sending, setSuccess] = useState(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const { executeRecaptcha } = useGoogleReCaptcha();

    async function Post(e) {
        e.preventDefault()
        setSuccess("Sending...");
        const token = await executeRecaptcha('yourAction');
        if (token === null) {
            setError("reCaptcha test failed, please reload and try again");
        } else {
            axios.post('/api/contact.php', {
                name: name,
                email: email,
                message: message,
                recaptchaResponse: token
            }).then(function (response) {
                if (response.data.message === 'OK') {
                    setSuccess("Message sent successfully")
                    setTimeout(() => {
                        props.close()
                    }, 2000);
                    setError(null)
                } else {
                    setError(response.data.message)
                    setSuccess(null)
                }
            }).catch(function (error) {
                setError(error.message)
                setSuccess(null)
            });
        }
    }
    function RenderError() {
        if (error === null) {
            return null
        } else {
            return <Alert className="m-2" variant="warning">{error}</Alert>
        }
    }
    function RenderSuccess() {
        if (sending === null) {
            return null
        } else {
            return <Alert className="m-2" variant="success">{sending}</Alert>
        }
    }
    return (
        <>
            <Row>
                <Col className="mx-auto">
                    <RenderError />
                    <Container className="contact-form">
                        <Form onSubmit={Post}>
                            <Form.Group>
                                <Row>
                                    <Col>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control placeholder="Name" value={name} onChange={(e) => setName(e.currentTarget.value)} max-length="20" required />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="name@example.com" value={email} onChange={(e) => setEmail(e.currentTarget.value)} max-length="50" required />
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Message</Form.Label>
                                <Form.Control as="textarea" rows={5} placeholder="Message" value={message} onChange={(e) => setMessage(e.currentTarget.value)} max-length="1000" required />
                            </Form.Group>
                            <p>This site is protected by reCAPTCHA and the Google
                                <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                                <a href="https://policies.google.com/terms"> Terms of Service</a> apply.</p>
                            <RenderSuccess/>
                            <button className="float-right black-cosyfish-button" type="submit">Submit</button>
                        </Form>
                    </Container>
                </Col>
            </Row>
        </>
    )
}