import { useState, useEffect } from 'react';

export default function useWindowDimensions() {

    const hasWindow = typeof window !== 'undefined';

    function getWindowDimensions() {
        let width = hasWindow ? window.innerWidth : null;
        let mobile = (width < 769)
        let pageWidth = width
        let height = hasWindow ? window.innerHeight : null;
        let pageHeight = height
        let widthMargin = 0
        let heightMargin = 0
        let aspectRatio = 1.77777778
        let newWidth = height * aspectRatio
        if(newWidth > width){
            height = width / aspectRatio
            heightMargin = (pageHeight - height) / 2
        } else {
            width = newWidth
            widthMargin = (pageWidth - width) / 2
        }
        return {
            width,
            height,
            widthMargin,
            heightMargin,
            mobile
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        if (hasWindow) {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [hasWindow]);

    return windowDimensions;
}