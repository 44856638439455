import React from 'react';

export default function BrandTile(props) {

        return (
            <div style={{ 'backgroundImage': `url(${props.image})` }} className={'grid-image ' + (props.close === false ? props.animation : "") + (props.close === false ? "" : props.exitAnimation)}>
                <div style={{ 'backgroundImage': `url(${props.logo})` }} className="grid-logo text-center"></div>
                {props.children}
            </div>
        );
}