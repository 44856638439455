import React, { useState, useEffect } from 'react';
import Slider from "react-slick";

import Bean from '../media/bean.jpg'
import Snowuns from '../media/snowuns.jpg'
import Yoga from '../media/yoga.jpg'
import Zombie from '../media/zombie.jpg'
import BeanP from '../media/bean.webp'
import SnowunsP from '../media/snowuns.webp'
import YogaP from '../media/yoga.webp'
import ZombieP from '../media/zombie.webp'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dynamicImage from '../utils/dynamicImage';
export default function BackgroundSlide(props) {
    const [slideImages, setSlideImages] = useState([])
    const [finishedLoading, setFinishedLoading] = useState(false)
    let imageIndex = 0
    const useProgressiveImage = src => {
        const [sourceLoaded, setSourceLoaded] = useState(null)
        useEffect(() => {
            const img = new Image()
            img.src = src
            img.onload = () => {
                setSourceLoaded(src)
                imageIndex = imageIndex + 1
                if (imageIndex === 4) {
                    props.incrementLoading()
                    setFinishedLoading(true)
                }
            }
        }, [src])
        return sourceLoaded
    }

    const BeanImage = useProgressiveImage(dynamicImage(props.supported, Bean, BeanP))
    const SnowunsImage = useProgressiveImage(dynamicImage(props.supported, Snowuns, SnowunsP))
    const YogaImage = useProgressiveImage(dynamicImage(props.supported, Yoga, YogaP))
    const ZombieImage = useProgressiveImage(dynamicImage(props.supported, Zombie, ZombieP))

    function shuffle(array) {
        array.sort(() => Math.random() - 0.5);
        return array;
    }
    useEffect(() => {
        setSlideImages(shuffle([BeanImage, SnowunsImage, YogaImage, ZombieImage]))
    }, [finishedLoading, BeanImage, SnowunsImage, YogaImage, ZombieImage])

    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: false,
        accessibility: false
    };

    return (
        <>
            
                <Slider {...settings} className={props.className}>
                    <div>
                        <div style={{ 'backgroundImage': `url(${slideImages[0]})` }} className='carousel-image'>
                        </div>
                    </div>
                    <div>
                        <div style={{ 'backgroundImage': `url(${slideImages[1]})` }} className='carousel-image'>
                        </div>
                    </div>
                    <div>
                        <div style={{ 'backgroundImage': `url(${slideImages[2]})` }} className='carousel-image'>
                        </div>
                    </div>
                    <div>
                        <div style={{ 'backgroundImage': `url(${slideImages[3]})` }} className='carousel-image'>
                        </div>
                    </div>
                </Slider>
        </>
    );
}