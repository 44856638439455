import React, { useState, useEffect } from 'react'
import {
	Router,
	Switch,
	Route,
	Link,
	Redirect
} from "react-router-dom";
import { AnimatedSwitch } from 'react-router-transition';
import './App.css';
import history from './History';
import LockupComponent from './components/Lockup'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CosyfishButton from './components/CosyfishButton'
import BackgroundSlide from './components/BackgroundSlide'
import BeatLoader from 'react-spinners/BeatLoader'
import BrandGrid from './components/BrandGrid'

import BeanLogo from './media/bean-logo2.png'
import SnowunsLogo from './media/snowuns-logo.png'
import ZombieLogo from './media/zombie-logo.png'
import BeanBg from './media/bean-bg.jpg'
import SnowunsBg from './media/snowuns-bg.jpg'
import ZombieBg from './media/zombie-bg.jpg'
import CosyfishBg from './media/cosyfish-bg.jpg'
import CosyfishLogo from './media/cosyfish-logo.png'
import BeanLogoP from './media/bean-logo2.webp'
import SnowunsLogoP from './media/snowuns-logo.webp'
import ZombieLogoP from './media/zombie-logo.webp'
import BeanBgP from './media/bean-bg.webp'
import SnowunsBgP from './media/snowuns-bg.webp'
import ZombieBgP from './media/zombie-bg.webp'
import CosyfishBgP from './media/cosyfish-bg.webp'
import CosyfishLogoP from './media/cosyfish-logo.webp'
import {
	GoogleReCaptchaProvider
  } from 'react-google-recaptcha-v3';
  
import Bean from './components/Bean'
import Snowuns from './components/Snowuns';
import Bio from './components/Bio';
import dynamicImage from './utils/dynamicImage';
export default function App() {

	const [supported, setSupported] = useState(false)
	const [loading, setLoading] = useState(0)
	const [hidePage, setHidePage] = useState(false)
	const [hideBrands, setHideBrands] = useState(false)
	const [background, changeBackground] = useState(true)
	const [bioLoading, setBioLoading] = useState(false)

	useEffect(() => {
		var elem = document.createElement('canvas');
		if (!!(elem.getContext && elem.getContext('2d'))) {
			setSupported(elem.toDataURL('image/webp').indexOf('data:image/webp') === 0)
		}
	}, [])


	function incrementLoading() {
		setLoading(loading => loading + 1)
	}
	function openGrid() {
		setHidePage(true)
		setTimeout(function () {
			history.push('/brands')
		}, 100);
		setTimeout(function () {
			changeBackground(true)
		}, 500)
	}
	function backHome() {
		history.push('')
	}
	function startBio() {
		setBioLoading(true)
	}
	function finishBio() {
		setBioLoading(false)
	}
	function startLoading() {
		setLoading(0)
	}
	function goToPage(url) {
		setHideBrands(true)
		setHidePage(false)
		history.push(url)
	}
	function goToBrands() {
		setHideBrands(false)
		setHidePage(true)
		setTimeout(function () {
			history.push('/brands')
		}, 100)
	}
	function closeGrid() {
		setHidePage(false)
		changeBackground(false)
		setTimeout(function () {
			history.push('/')
		}, 500);
	}
	useEffect(() => {
		if (loading === 2) {
			setTimeout(function () {
				changeBackground(false)
			}, 2000);
		}
	}, [loading])
	const useProgressiveImage = src => {
		const [sourceLoaded, setSourceLoaded] = useState(null)
		useEffect(() => {
			if (loading === 2) {
				const img = new Image()
				img.src = src
				img.onload = () => {
					setSourceLoaded(src)
				}
			}
		}, [src, loading])
		return sourceLoaded
	}

	const BeanLogoImage = useProgressiveImage(dynamicImage(supported, BeanLogo, BeanLogoP))
	const SnowunsLogoImage = useProgressiveImage(dynamicImage(supported, SnowunsLogo, SnowunsLogoP))
	const ZombieLogoImage = useProgressiveImage(dynamicImage(supported, ZombieLogo, ZombieLogoP))
	const CosyfishBgImage = useProgressiveImage(dynamicImage(supported, CosyfishBg, CosyfishBgP))
	const BeanBgImage = useProgressiveImage(dynamicImage(supported, BeanBg, BeanBgP))
	const ZombieBgImage = useProgressiveImage(dynamicImage(supported, ZombieBg, ZombieBgP))
	const SnowunsBgImage = useProgressiveImage(dynamicImage(supported, SnowunsBg, SnowunsBgP))
	const CosyfishLogoImage = useProgressiveImage(dynamicImage(supported, CosyfishLogo, CosyfishLogoP))

	return (
		<>
			<GoogleReCaptchaProvider reCaptchaKey="6Lfkf68cAAAAAPq6JdI_WvxzljEcNrPwlvfnYIA3">
				<Router history={history}>
					<div className="center">
						{loading > 0 ? null : <BeatLoader color='#583c96' />}
					</div>
					<AnimatedSwitch
						atEnter={{ opacity: 0 }}
						atLeave={{ opacity: 0 }}
						atActive={{ opacity: 1 }} className="animated-switch"
					>
						{/*<Route exact path="/brands/zombie-snowmen">
						<ZombieSnowmen goToBrands={goToBrands} className={(hidePage ? "fade-out" : "fade-in") + " above-brands"} startLoading={startLoading} incrementLoading={incrementLoading} logo={ZombieLogoImage} />
					</Route>*/}

						<Route exact path="/brands/bean">
							<Bean goToBrands={goToBrands} className={(hidePage ? "fade-out" : "fade-in") + " above-brands bean-page"} startLoading={startLoading} incrementLoading={incrementLoading} logo={BeanLogoImage} supported={supported} />
						</Route>
						<Route exact path="/brands/snowuns">
							<Snowuns goToBrands={goToBrands} className={(hidePage ? "fade-out" : "fade-in") + " above-brands"} startLoading={startLoading} incrementLoading={incrementLoading} logo={SnowunsLogoImage} supported={supported} />
						</Route>

						<Route path="/">
							<div key="slider-div" className={(background ? "black" : "purple") + (hidePage ? " fade-out" : "") + ' slider-div'}>
								<BackgroundSlide incrementLoading={incrementLoading} supported={supported} />
							</div>
							<div key="main-div" className={hidePage ? "fade-out" : "fade-in"}>

								<Container className="top-align">
									<Row>
										<Col lg={12} className="mx-auto">
											<LockupComponent incrementLoading={incrementLoading} parentLoading={loading} supported={supported} />
										</Col>
									</Row>
									<div className={(background ? "lockup-text-container-hidden" : "lockup-text-container")}>
										<div className="text-center">
											<p className="lockup-text text-white">FUN CHARACTER PRODUCTS</p>
										</div>
										<Row className="button-container">
											<Col xs={12} sm={6}>
												<CosyfishButton content="BRANDS" className="right-float" onClick={() => openGrid()} />
											</Col>
											<Col xs={12} sm={6}>
												<Link to="about-us" ><CosyfishButton content={bioLoading ? <BeatLoader color='#FFFFFF' /> : "ABOUT US"} className="left-float" /></Link>
											</Col>
										</Row>
									</div>
								</Container>
							</div>
						</Route>
					</AnimatedSwitch>
					<Switch>
						<Route path="/about-us">
							<Bio startBio={startBio} finishBio={finishBio} snowuns={SnowunsLogoImage} zombie={ZombieLogoImage} bean={BeanLogoImage} backHome={backHome} supported={supported} />
						</Route>
						<Route path="/brands/">
							<BrandGrid className="brand-grid" hideBrands={hideBrands} setHideBrands={setHideBrands} closeGrid={() => closeGrid()} bg1={CosyfishBgImage} logo1={CosyfishLogoImage} bg2={SnowunsBgImage} logo2={SnowunsLogoImage} bg3={ZombieBgImage} logo3={ZombieLogoImage} bg4={BeanBgImage} logo4={BeanLogoImage} goToPage={goToPage} />
						</Route>
						<Route path="/">
							<Redirect to="/" />
						</Route>
					</Switch>
				</Router>
			</GoogleReCaptchaProvider>
		</>
	);
}