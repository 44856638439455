import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import useWindowDimensions from './useWindowDimensions'
import { Modal, Button } from 'react-bootstrap';

import HorizontalLogo from '../media/horizontal-logo.png'
import MiniLogo from '../media/mini-logo.png'
import StarEditions from '../media/star-editions.png'
import CosyfishMap from '../media/cosyfish-map.png'
import MapMobile from '../media/map-mobile.png'
import LittleFish from '../media/little-fish.png'
import SnowunFooter from '../media/snowun-footer.png'
import HorizontalLogoP from '../media/horizontal-logo.webp'
import MiniLogoP from '../media/mini-logo.webp'
import StarEditionsP from '../media/star-editions.webp'
import CosyfishMapP from '../media/cosyfish-map.webp'
import MapMobileP from '../media/map-mobile.webp'
import LittleFishP from '../media/little-fish.webp'
import SnowunFooterP from '../media/snowun-footer.webp'
import dynamicImage from '../utils/dynamicImage'
import CosyfishButton from './CosyfishButton'
import Contact from './Contact';

export default function Bio(props) {
    const [loading, setLoading] = useState(0)
    const [show, setShow] = useState(false)
    const [exit, setExit] = useState(false)
    const { height, width, heightMargin, widthMargin, mobile } = useWindowDimensions();
    useEffect(() => {
        if (loading === 0) {
            props.startBio()
        }
        if ((mobile === true && loading > 1) || (mobile === false && loading > 4)) {
            setTimeout(function () {
                props.finishBio()
            }, 500)
        }
    }, [loading, mobile, props])
    function ExitBio() {
        setExit(true)
        setTimeout(function () {
            props.backHome()
        }, 500)
    }
    const useProgressiveImage = src => {
        const [sourceLoaded, setSourceLoaded] = useState(null)
        useEffect(() => {
            const img = new Image()
            img.src = src
            img.onload = () => {
                setLoading(loading => loading + 1)
                setSourceLoaded(src)
            }
        }, [src])
        return sourceLoaded
    }
    const HorizontalLogoImage = useProgressiveImage(dynamicImage(props.supported, HorizontalLogo, HorizontalLogoP))
    const MiniLogoImage = useProgressiveImage(dynamicImage(props.supported, MiniLogo, MiniLogoP))
    const StarEditionsLogo = useProgressiveImage(dynamicImage(props.supported, StarEditions, StarEditionsP))
    const CosyfishMapImage = useProgressiveImage(dynamicImage(props.supported, CosyfishMap, CosyfishMapP))
    const LittleFishImage = useProgressiveImage(mobile ? null : dynamicImage(props.supported, LittleFish, LittleFishP))
    const MapMobileImage = useProgressiveImage(mobile ? dynamicImage(props.supported, MapMobile, MapMobileP) : null)
    const SnowunFooterImage = useProgressiveImage(mobile ? dynamicImage(props.supported, SnowunFooter, SnowunFooterP) : null)
    function TextCalc() {
        let widthSize = (width / 59)
        let heightSize = (heightMargin / 50)
        if (heightSize > widthSize) {
            return heightSize + 1
        } else {
            return widthSize
        }
    }
    if ((mobile === true && loading > 5) || (mobile === false && loading > 4)) {
        return (
            <>
                <div className={exit ? "bio scale-in-center scale-out-center" : "bio scale-in-center"}>
                    <div className="brand-background bio-background">
                    </div>
                    <div className="brand-mobile bio-mobile" style={{ fontSize: (width > 575 ? 27 : width / 25) }}>
                        <div className="bio-mobile-container">
                            <img src={HorizontalLogoImage} alt="Cosyfish Logo" className="horizontal-cosyfish-mobile" />
                            <div style={{ textAlign: 'left' }}>
                                <p className="bio-main-1-mobile">Carl and Nicky founded<img src={MiniLogoImage} alt="Mini COSYFISH logo" style={{ width: width / 3.5 }} className="bio-main-1-logo-mobile" />to share their passion for character art and design</p>
                                <p className="bio-main-2-mobile">Carl creates exciting new and iconic characters for<br></br>
                                    all ages and demographics. He is a Disney, Warner
                                    Bros and Lucasfilm approved artist. Nicky is an
                                    advertising board level professional. She combines
                                    originality and flare to solve creative dilemmas<br></br>
                                    with sound research and lateral thinking. They<br></br>
                                    have worked on a huge variety of licenses together
                                    ranging from toys and games to publishing, tv and film. </p>
                                <p className="bio-main-2-mobile">Now you can share and enjoy their unique quality character brands and products :)</p>
                                <p className="bio-main-3-mobile"><img src={MiniLogoImage} alt="Mini COSYFISH logo" className="bio-main-3-logo-mobile" style={{ width: width / 3.5 }} />is committed to quality and sustainability and we are proud to have partnered
                                    with Star Editions to ethically source and produce our products.</p>
                            </div>
                            <button onClick={() => setShow(true)} className="contact-mobile">Contact Us</button>
                            <div className="bio-footer-mobile">
                                <img src={MapMobileImage} alt="Cosyfish Map" className="cosyfish-map-mobile" />
                                <img src={SnowunFooterImage} alt="Snowuns Character" className="cosyfish-footer-snowuns" />
                            </div>
                        </div>
                    </div>
                    <div style={{ height: height, width: width, marginTop: heightMargin, marginBottom: heightMargin, marginLeft: widthMargin, marginRight: widthMargin, position: 'absolute', fontSize: TextCalc() }} className="desktop-page white-text">
                        <div className="horizontal-cosyfish-container">
                            <img src={HorizontalLogoImage} alt="Cosyfish Logo" className="horizontal-cosyfish" />
                        </div>
                        <img src={MiniLogoImage} alt="Mini COSYFISH logo" className="bio-main-1-logo" />
                        <p className="bio-main-1">Carl and Nicky founded &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to share their passion for character art and design.<br></br></p>
                        <p className="bio-main-2">Carl creates exciting new and iconic characters for all ages and demographics. He is a Disney, Warner Bros and Lucasfilm <br></br> approved artist. Nicky is an advertising board level professional. She combines originality and flare to solve creative <br></br> dilemmas with sound research and lateral thinking. They have worked on a huge variety of licenses together ranging from <br></br>toys and games to publishing, tv and film.</p>
                        <p className="bio-main-3">Now you can share and enjoy their unique quality character brands and products :)</p>
                        <button onClick={() => setShow(true)} className="contact-desktop">Contact Us</button>
                        <img src={MiniLogoImage} alt="Mini COSYFISH logo" className="bio-main-4-logo" />
                        <a href="https://stareditions.com/"><img src={StarEditionsLogo} alt="Star Editions Logo" className="star-editions-logo" /></a>
                        <p className="bio-main-4">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;is committed to quality and sustainability and we are proud to have partnered with &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; to ethically <br></br> source and produce our products.</p>
                        <div className="bio-footer-cont">
                            <img src={CosyfishMapImage} alt="COSYFISH brand map" className="cosyfish-map" />
                            <img src={props.snowuns} alt="snowuns logo" className="cosyfish-map-snowuns" />
                            <img src={props.zombie} alt="zombie logo" className="cosyfish-map-zombie" />
                            <img src={props.bean} alt="bean logo" className="cosyfish-map-bean" />
                            <div style={{ fontSize: TextCalc() * 1.225 }}>
                                <p className="snowuns-map-desc">Fun-loving little characters provide a positive eco-message for younger children.</p>
                                <p className="zombie-map-desc">Spooky scares for older kids.</p>
                                <p className="bean-map-desc">Enjoying the simple pleasures in life...</p>
                            </div>
                            <img src={LittleFishImage} alt="little cosyfish logo" className="little-cosyfish" />
                        </div>
                    </div>
                    <div className="brand-flexi-footer desktop-page bio-footer" style={{ height: (heightMargin + (height * 0.3615)) }}>

                    </div>
                    <FontAwesomeIcon icon={faTimes} size="3x" onClick={() => ExitBio()} className="close-button-small-screen" />
                    <FontAwesomeIcon icon={faTimes} size="5x" onClick={() => ExitBio()} className="close-button-large-screen" />
                </div>

                <Modal show={show} onHide={() => setShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className="Lato">Contact Us</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><Contact close={() => setShow(false)}/></Modal.Body>
                </Modal>
            </>
        )
    } else {
        return (
            null
        );
    }
}